<template>
  <v-card class="mx-auto" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <span
            class="text-h6 ml-1"
            v-text="`${$route.meta.title} ${loading ? '' : `(${total_rows})`}`"
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip v-if="login.role_id == 1" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="teal"
                :loading="btn_xls_ldg"
                :disabled="data.length == 0"
                @click.prevent="downloadXls"
              >
                <v-icon> mdi-table-arrow-down </v-icon>
              </v-btn>
            </template>
            <span v-text="'Descargar tabla XLS'" />
          </v-tooltip>
          <BtnCircle
            tLabel="Concentradores"
            tPosition="bottom"
            icon="mdi-group"
            color="teal darken-3"
            size="x-small"
            :to="{ name: 'proveedores.concentradores' }"
          />
          <BtnCircle
            tLabel="Tipos de prestación"
            tPosition="bottom"
            icon="mdi-checkerboard"
            color="brown darken-3"
            size="x-small"
            :to="{ name: 'proveedores.tipos_prestaciones' }"
          />
          <BtnCircle
            tLabel="Coberturas"
            tPosition="bottom"
            icon="mdi-file-certificate"
            color="purple darken-3"
            size="x-small"
            :to="{ name: 'proveedores.coberturas' }"
          />
          <BtnCircle
            tLabel="Tipos"
            tPosition="bottom"
            icon="mdi-clipboard-list"
            color="info"
            size="x-small"
            :to="{ name: 'proveedores.tipos' }"
          />
          <BtnCircle
            v-if="login.permissions.provider_groups.read"
            tLabel="Redes"
            tPosition="bottom"
            icon="mdi-table-network"
            color="warning"
            size="x-small"
            :to="{ name: 'redes.proveedores' }"
          />
          <BtnCircle
            v-if="login.permissions.providers.create"
            tLabel="Crear"
            tPosition="bottom"
            icon="mdi-plus"
            color="success"
            size="x-small"
            :to="{ name: `${page_route}.store` }"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" xs="12">
          <v-data-table
            :headers="headers"
            :items="data"
            :search="search"
            :loading="loading"
          >
            <template v-slot:item.provider_brand="{ item }">
              <v-icon
                v-if="!item.provider_brand"
                small
                color="orange"
                v-text="'mdi-alert'"
              />
              <span v-else v-text="item.provider_brand" />
            </template>
            <template v-slot:item.observation="{ item }">
              <v-icon
                v-if="item.observation"
                small
                color="orange"
                v-text="'mdi-alert'"
              />
            </template>
            <template v-slot:item.cs="{ item }">
              <v-icon
                v-if="!item.cs"
                small
                color="orange"
                v-text="'mdi-alert'"
              />
              <span v-else v-text="item.cs" />
            </template>
            <template v-slot:item.revision="{ item }">
              <v-icon
                v-if="item.revision"
                small
                color="success"
                v-text="'mdi-check'"
              />
              <v-icon v-else small color="orange" v-text="'mdi-alert'" />
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    fab
                    x-small
                    color="primary"
                    :to="{
                      name: `${page_route}.view`,
                      params: { id: item.id },
                    }"
                  >
                    <v-icon v-text="'mdi-eye-outline'" />
                  </v-btn>
                </template>
                <span v-text="'Ver'" />
              </v-tooltip>
              <v-tooltip v-if="login.permissions.benefits.read" left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="ml-1"
                    v-on="on"
                    fab
                    x-small
                    color="warning"
                    :to="{
                      name: 'prestaciones',
                      params: { provider_id: item.id },
                    }"
                  >
                    <v-icon v-text="'mdi-file'" />
                  </v-btn>
                </template>
                <span v-text="'Lista de precios'" />
              </v-tooltip>
            </template>

            <template v-slot:item.general="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <span v-text="item.name + '. '" /> <br />
                  <span v-text="item.trade_name" />
                </template>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
//import functions
import Axios from "axios";
import {
  URL_API,
  headersToken,
  base64ToArrayBuffer,
  dateTimeToFile,
} from "../../control";
import { index } from "../../requests/pageRequest";
//import components
import BtnCircle from "../../components/BtnCircle.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    FaqDlg,
  },
  data() {
    return {
      //route name and page title
      page_route: "providers",
      //vars control
      login: this.$store.getters.getLogin,
      loading: true,
      //data from api
      data: [],
      total_rows: 0,
      //vars table
      search: "",
      headers: [
        { text: "IDP", filterable: true, value: "id" },
        { text: "Nombre comercial", filterable: true, value: "trade_name" },
        { text: "Nombre", filterable: true, value: "name" },
        { text: "Estado", filterable: true, value: "entity" },
        { text: "Municipio", filterable: true, value: "municipality" },
        { text: "Tipo", filterable: true, value: "provider_type" },
        { text: "Concentrador", filterable: false, value: "provider_brand" },
        { text: "Observación", filterable: true, value: "observation" },
        { text: "C.S.", filterable: true, value: "cs" },
        { text: "Segunda firma red", filterable: false, value: "revision" },
        { text: "Acción", value: "action", sortable: false, width: "100px" },
      ],
      btn_xls_ldg: false,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    downloadXls() {
      this.btn_xls_ldg = true;

      Axios.post(
        URL_API + "/providers/xls",
        {
          items: this.data,
        },
        headersToken(this.login.token)
      ).then((res) => {
        if (res.data.success) {
          this.fileDownload(res.data.data, "xlsx", "asegurados");
          this.btn_xls_ldg = false;
        } else {
          this.btn_xls_ldg = false;
          this.$swal.fire(msgAlert("error", "Error al generar archixo XLSX"));
        }
      });
    },
    fileDownload(base64, ext, name) {
      const blob = new Blob([base64ToArrayBuffer(base64)], {
        type: "application/" + ext,
      });
      let downloadLink = document.createElement("a");
      downloadLink.setAttribute("target", "_blank");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "SM_" + name + "_" + dateTimeToFile() + "." + ext;
      downloadLink.click();
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    index(this.page_route, this.login.token, [
      {
        name: "active",
        value: 1,
      },
      {
        name: "id",
        value: this.login.id,
      },
    ]).then((response) => {
      this.data = response.data;
      this.total_rows = this.data.length;
      this.loading = false;
    });
  },
};
</script>